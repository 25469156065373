
.map-wrapper {
    display: block;
    /* height: 100vh;
    width: 100vw; */
    height: 100%;
    width: 100%;
}
  
.map-container {
    height: 100%;
    width: 100%;
}