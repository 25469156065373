.reviews-container {
    width: 940px;
    display: flex;
    flex-direction: row;
    background-color: transparent;
}

.reviews-page-left-side {
    background: rgba(0,0,0,.6);
    width: 940px;
    border-bottom: 1px solid #000;
    margin-top: 10px;

    color: #fff;
    padding: 20px;
    /* display: block; */
    text-decoration: none;
    text-transform: uppercase;
}

@media screen and (max-width: 430px) {
    .reviews-container {
        width: 930px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 540px) {
    .reviews-container {
        width: 930px;
        margin-left: 10px;
    }
    
}
@media screen and (max-width: 820px) {
    .reviews-container {
        width: 930px;
        margin-left: 10px;
    }
    
}