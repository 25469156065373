.app-container {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  background-image: url('./assets/images/grey-wall.jpg');
  height: 100%;
  /* height: 100vh; */
}

.extended-down {
  height: 100%;
}

.splash-page-main-div-hr {
  height: 5px;
  border-width: 0;
  color: black;
  background-color: black;
  width: 940px;
}

@media screen and (min-height: 1290px) {
  .app-container {
    height: 100vh;
    min-width: 1000px;
  }
  .extended-down {
    height: 100%;
  }
}

/* 
@media screen and (max-width: 820px) {
  .app-container {
    height: auto;
  }
  .splash-page-main-div-hr {
    width: auto;
  }
}


@media screen and (max-width: 767px) {
  .app-container {
    height: auto;
  }
  .splash-page-main-div-hr {
    width: auto;
  }
}

@media screen and (max-width: 390px) {
  .app-container {
    width: 390px;
    
  }
  .splash-page-main-div-hr {
    width: 390px;
  }
} */

@media screen and (max-width: 430px) {
  .app-container {
    display: block;
    min-width: 940px;
  }
}

@media screen and (max-width: 540px) {
  .app-container {
    display: block;
    min-width: 950px;
  }
}
@media screen and (max-width: 820px) {
  .app-container {
    display: block;
    min-width: 950px;
  }
}