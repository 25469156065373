.hours-location-container {
    overflow: hidden;
    width: 305px;
    float: right;
    font-family: Georgia, "Bitstream Charter", serif;
}

.hours-ul {
    list-style-type: none;
}

.hours-location-hr {
    height: 2px;
    border-width: 0;
    background-color: black;
    margin-bottom: 15px;
  }

.splash-right-textwidget-strong,
.splash-right-textwidget-strong-days {
    color: black;
    line-height: 25px;
}
  
.splash-right-textwidget-strong-days {
    line-height: 25px;
}

.widget-container {
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    margin: 0 0 18px 0;
}
.shuttle-img {
    max-width: 100%;
    height: auto;
  }

.widget-container .wp-caption img {
    margin: auto;
}


.widget-title {
    /* color: #222; */
    color: black;
    font-weight: bold;
    margin-bottom: 0;
    font-size: 15px;
    line-height: 18px;
}

.widget-title,
.wp-caption-text {
  font-family: "Helvetica Neue", Arial, Helvetica, "Nimbus Sans L", sans-serif;
}

/* @media screen and (max-width: 820px) {
    .location {
        display: none;
    }
} */

/* @media screen and (max-width: 940px) {
    .hours-location-container {
        max-width: 200px;
    }
} */
/* .widget-title {
    font-size: 15px;
} */

/* @media screen and (max-width: 763px) {
    .hours-location-container {
        display: none;
    }
} */