.carousel-container {
    width: 100%;
}
.carousel-img-contianer {
    /* width: 700px; */
    /* width: 500px; */
    height: 280px;
}

.carousel-img {
    width: 100%;
    height: 100%;
}

/* @media screen and (max-width: 390px) {
    .carousel-container {
        width: 90%;
    }

    .carousel-img-contianer {
        height: 200px;
    }
} */