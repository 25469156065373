.navbar-area {
  background: rgba(0,0,0,.6);
  border-bottom: 1px solid #000;
  margin-top: 10px;
}

.site-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: center;
}

a.site-logo {
  font-size: 26px;
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
}

.site-navbar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}
.site-navbar ul li a {
  color: #fff;
  padding: 20px;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
}
.site-navbar ul li a:hover {
  background: rgba(255,255,255,.1);
}

.active {
  background: rgba(255,255,255,.1);
}
/* regular navbar css end */


/* nav-toggler css start */
.nav-toggler {
  border: 3px solid #fff;
  padding: 5px;
  background-color: transparent;
  cursor: pointer;
  height: 39px;
  display: none;
}
.nav-toggler span, 
.nav-toggler span:before, 
.nav-toggler span:after {
  width: 28px;
  height: 3px;
  background-color: #fff;
  display: block;
  transition: .3s;
}
.nav-toggler span:before {
  content: '';
  transform: translateY(-9px);
}
.nav-toggler span:after {
  content: '';
  transform: translateY(6px);
}
.nav-toggler.toggler-open span {
  background-color: transparent;
}
.nav-toggler.toggler-open span:before {
  transform: translateY(0px) rotate(45deg);
}
.nav-toggler.toggler-open span:after {
  transform: translateY(-3px) rotate(-45deg);
}

@media screen and (max-width: 430px) {
  .nav-bar-container {
    margin: 0 10px 0 10px;
  }
  .home-page-left-side {
      margin-left:10px;
  }
  .home-page-right-side {
      margin-right: 10px;
  }
  .home-map-container {
    display: block;
    width: 300px;
  }

  img {
    max-width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .nav-bar-container {
    margin: 0 10px 0 10px;
  }

  img {
    max-width: 100%;
  }
}

@media screen and (max-width: 820px) {
  .nav-bar-container {
    margin: 0 10px 0 10px;
  }
  img {
    max-width: 100%;
  }
}
