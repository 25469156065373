.contact-us-form-container-div {
    width: 940px;
    display: flex;
    flex-direction: row;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}

.contact-us-form-inner-div {
    background: rgba(0,0,0,.6);
    border-bottom: 1px solid #000;
    margin-top: 10px;

    color: #fff;
    padding: 20px;
    display: block;
    text-decoration: none;
    /* text-transform: uppercase; */
}


.success {
    margin: 20px 0px 20px 0px;
    background-color: #28a745;
    text-decoration: none;
    text-transform: uppercase;
}

.email-form-input {
    width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
}

/* .contact-us-page-left-side {

} */

.contact-us-submit-button {
    width: 100%;
    background-color: rgb(118, 123, 118);
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
.contact-us-submit-button:hover {
    background-color: #5e625f;
}

@media screen and (max-width: 430px) {
    .contact-us-page-left-side {
        width: 900px;
    }
    .contact-us-form-container-div {
        width: 930px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 540px) {
    .contact-us-page-left-side {
        width: 900px;
    }
    .contact-us-form-container-div {
        width: 930px;
        margin-left: 10px;
    }
    
}
@media screen and (max-width: 820px) {
    .contact-us-page-left-side {
        width: 900px;
    }

    .contact-us-form-container-div {
        width: 930px;
        margin-left: 10px;
    }
    
}